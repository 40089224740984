import { Typography } from "@mui/material";
import React, { useRef } from "react";
import { Avatar } from "@mui/material";

import PopOverWraper from "./PopOverWraper";
import avitar from "../assets/avatar.svg"

const Question = ({ info }) => {
  const childRef = useRef();

  const handleClick = (e) => {
    childRef.current.childFunction1(e);
  };
  
  return (
    <>
      <div className="flex gap-x-3 md:gap-x-4 cursor-pointer" 
      onDoubleClick={handleClick}
      >
        <div>
          <Avatar   sx={{ md: { width: '40px' }, sm: { width: '10px !important' } }} src={avitar}></Avatar>
        </div>
        <div className="flex-1 pt-1 text-[12px] md:text-[15px]">{info.question}</div>
      </div>
      <PopOverWraper ref={childRef}>
        <Typography sx={{ p: 2 }}>{info.response.prompt}</Typography>
      </PopOverWraper>
    </>
  );
};

export default Question;
