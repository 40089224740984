import { useState } from "react";

export function usePopover() {
  const [anchorEl, setAnchorEl] = useState();


  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return { anchorEl, handleClick, handleClose, id, open };
}
