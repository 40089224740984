import "./App.css";
import { Navigate, Route, Routes } from "react-router-dom";
import SignIn from "./pages/SignIn";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import Landing from "./pages/Landing";
import { useSelector } from "react-redux";
import Wrapper from "./pages/Wrapper";

function App() {
  const User = useSelector((state) => {
    return state.user.isSigned;
  });

  console.log("render")
  const PrivateRoute = ({ children }) => {
    if (User) {
      return children;
    } else {
      return <Navigate to="/" />;
    }
  };


  return (
    <Routes>
      <Route exact path="/" element={<Wrapper />} />
      <Route
        exact
        path="/signin"
        element={
          <PrivateRoute>
            <SignIn />
          </PrivateRoute>
        }
      />
      <Route
        exact
        path="/landing"
        element={
          <PrivateRoute>
            <Landing />
          </PrivateRoute>
        }
      />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
}

export default App;
