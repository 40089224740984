import React from "react";
import { FaMicrophone } from "react-icons/fa";
import { RiSendPlaneFill } from "react-icons/ri";
import { useCustomDispatch } from "../hooks/useCustomDispatch";
import { InputBase } from "@mui/material";

const InputPrompt = () => {
    const {handleIsNew,searchTerm,isLoading,handleSearchChange,handlekeydown}=useCustomDispatch();
    
    return (
    <div className="bg-white rounded-[40px] flex h-[63px] items-center px-[10px]  md:m-0">
      <FaMicrophone className="text-black text-[23px] mx-[10px]" />
      <InputBase
        type="text"
        className="flex-1 outline-none text-black text-[17px]"
        placeholder="Ask a question"
        onChange={handleSearchChange}
        value={searchTerm}
        onKeyDown={handlekeydown}
      />
      <button
        className={`rounded-full ml-[8px] bg-[#EB0A1E] text-[20px] h-[44px] w-[44px] flex justify-center items-center cursor-pointer ${isLoading?'rotate':''}`}
        onClick={()=>handleIsNew(searchTerm)}
      >
        <RiSendPlaneFill className="text-white" />
      </button>
    </div>
  );
};

export default InputPrompt;
