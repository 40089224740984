import React from "react";
import logo from "../assets/logo.png";
import { AiOutlineMenu } from "react-icons/ai";
import { BiSolidUserCircle } from "react-icons/bi";
import LandingPrompt from "../components/LandingPrompt";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ChatBoard from "../components/ChatBoard";
const Landing = () => {
  const isNew = useSelector((state) => {
    return state.chatStore.isNew;
  });

  const navigate = useNavigate();

  function handleNavigate() {
    navigate("/");
  }

  return (
    <div className={`w-screen h-screen ${isNew?"image-container":"image-container-blur"}  `}>
      <div className={`flex justify-center pt-[10px] md:pt-[40px]  ${isNew?"md:pb-[32px]":'md:pb-[20px]'}`}>
      <nav className="flex w-[1020px] px-[20px] md:px-0">
        <div className="w-[33%] text-[25px] text-white flex md:justify-left justify-start">
          <AiOutlineMenu className="text-white" />
        </div>
        <div className="w-[33%] flex justify-center" onClick={handleNavigate}>
          <img src={logo} className="h-[28px]" alt="engage logo" />
        </div>
        <div className="w-[33%] flex md:justify-end justify-end">
          <BiSolidUserCircle className="text-[35px] text-[#F5F6F7]" />
        </div>
      </nav>
      </div>
      <div className="flex justify-center overflow-hidden">
        <div className="">{isNew ? <LandingPrompt /> : <ChatBoard />}</div>
      </div>
    </div>
  );
};

export default Landing;
