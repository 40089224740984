import { greetings } from "../../raw/Raw";

export function responseFilter(response,question) {
  let filterResponse = {
    answer: "",
    docs:[],
  };

  filterResponse.answer = response.text.answer_text.replace(
    /(https?:\/\/\S+)|(www\.\S+)/g,
    (match, p1, p2) => {
      const url = p1 || `http://${p2}`;
      return `<a href="${url}" class="link" target="_blank">${p2}</a>`;
    }
  );

  filterResponse.answer = response.text.answer_text.replace(
    /\*\*(.*?)\*\*/g,
    "<b>$1</b>"
  );
  

  if (response.text.link) {
    if (response.text.answer_text.includes("I'm sorry")) {
    }
    else if(greetings.includes(question.toLowerCase()))
    {

    }
     else {
    
      filterResponse.link = response.text.link;
    }
  }
  if(response.prompt){

    filterResponse.prompt=response.prompt
  }
  
  if(response.text.docs)
  {
    if(response.text.docs.length>0)
    {
    filterResponse.docs=response.text.docs;
    }

  }

  if(response.text.table)
  {
    filterResponse.table=response.text.table;
  }

  if(response.text?.model_image_info){

    if(response?.text?.link?.length>0)
    {
        if(!response.text.link.some(obj=>obj.url[0].includes("youtube")))
        {
          if(response.text.model_image_info.length>0)
          filterResponse.images = response.text.model_image_info
        }
    }
    else{
      filterResponse.images = response.text.model_image_info

    }

  
  }
  if(response.memory)
  {

    filterResponse.memory = response.memory;
  }
  
  console.log(filterResponse);
  return filterResponse;
}
