import ReactMarkdown from 'react-markdown';
import gfm from 'remark-gfm';

export const ReactMark = ({info}) => {
  return (<>
    {info.table && 
        <div className="markdown-table text-[9px] md:text-[12px]  mt-[10px] style-3">
        <ReactMarkdown remarkPlugins={[gfm]}>{info.table}</ReactMarkdown>
        {/* <h2 className='font-bold mt-[20px] ml-[4px] text-[13px]'>Visit the Head to Head page to learn more? <span className="text-[#EB0A1E] underline  cursor-pointer">GO</span> </h2>   */}
        </div>

    }</>
    
  )
}
