import { createSlice } from "@reduxjs/toolkit";
import { fetchAnswer } from "../thunks/fetchAnswer";

const chatStore = createSlice({
  name: "chatstore",
  initialState: {
    currentQuestion: "",
    chat: [],
    isNew: true,
    isLoading: false,
    error: null,
    memory: {
      model: "",
      trim: "",
      year: "",
    },
  },

  reducers: {
    changeSearchTerm(state, action) {
      state.currentQuestion = action.payload;
    },
    changeIsNew(state, action) {
      state.isNew = action.payload;
    },
  },
  extraReducers(builder) {
    builder.addCase(fetchAnswer.pending, (state, action) => {
      state.chat.push({
        question: state.currentQuestion,
        response: { answer: "  " },
      });
      state.isLoading = true;
      state.currentQuestion = "";
    });
    builder.addCase(fetchAnswer.fulfilled, (state, action) => {
      state.chat[state.chat.length - 1].response = action.payload;
      state.memory=action.payload.memory;
      state.isLoading = false;
    });
    builder.addCase(fetchAnswer.rejected, (state, action) => {
      console.log("rejected");
      state.isLoading = false;
      state.chat[state.chat.length - 1].response.answer =
        "<strong>We are experiencing a technical issue preventing us from providing an answer now. A team is working to resolve the issue. Please allow a little time and then ask your question again. We appreciate your patience.</strong>";
      state.error = action.error;
    });
  },
});

export const { changeSearchTerm, changeIsNew } = chatStore.actions;
export default chatStore.reducer;
