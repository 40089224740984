import { configureStore } from "@reduxjs/toolkit";
import chatStore from "./slices/ChatStoreSlice";
import userSlice from "./slices/UserSlice";

const store = configureStore({
  reducer: {
    chatStore: chatStore,
    user : userSlice
  },
});

export default store;
