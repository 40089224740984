import React from "react";
import { useSelector } from "react-redux";
import Question from "./Question";
import Answer from "./Answer";
import Share from "./Share";

const ChatBody = () => {
  const {chat,isLoading} = useSelector((state) => {
    return state.chatStore;
  });

  const content = chat.map((obj, i) => {
    return (
      <div key={i} >
        <div className="bg-white mb-2 rounded-[23px] md:rounded-xl p-4  text-[18px] text-[#333333bd]">
          <Question info={obj} />
        </div>

        <div className="bg4chatanswer rounded-[23px] md:rounded-xl relative">
          <Answer info={obj} last={i === chat.length - 1 ? true : false} />
         {!isLoading&&<Share/>}
        </div>
      </div>
    );
  });
  
  return <div>{content}</div>;
};

export default ChatBody;
