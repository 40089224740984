import React from "react";
import { BsChevronRight } from "react-icons/bs";
import { useCustomDispatch } from "../hooks/useCustomDispatch";

const TrendingTopics = () => {
  const trendingPrompts = [
    "Tell me about the 2024 Land Cruiser",
    "Explain the Beyond Zero mission to me",
    "Write a thank you email to customer",
    "Tell me about the 2024 Grand Highlander",
    "Tell me about the 2024 Prius Prime",
  ];

  const { handleIsNew } = useCustomDispatch();

  return (
    <div className=" mx-[20px] mt-[22px] md:mx-0">
      <h2 className=" text-[10px] md:text-[13px] mt-4 mb-[10px] md:mb-[14px] tracking-[5px] uppercase font-[500] ">
        Trending Topics
      </h2>
      <div className="flex gap-x-4 flex-wrap gap-y-3">
        {trendingPrompts.map((prompt, i) => {
          return (
            <div
              key={i}
              onClick={() => handleIsNew(prompt)}
              className="cursor-pointer  relative flex justify-center p-2 md:p-5 bg-[#8080808f] w-[100px] h-[90px] md:w-[166px] md:h-[150px] rounded-[20px] text-[11px] md:text-[17px]"
            >
              {prompt}
              <BsChevronRight className="absolute  text-[10px] md:text-[16px] bottom-[15px] right-[12px]" />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default TrendingTopics;
