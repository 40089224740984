import React from "react";

const CarLinks = ({ info }) => {

  return (
    <>
      {info?.link?.length > 0 &&
        info.link.map((obj) => {
          return (
            <>
            {obj.url[0].includes("youtube") ? (
               
                <>               
                  {
                    console.log("hello")
                  }
                
                 <div className="flex justify-center pb-8">
                  <div className="md:w-[75%] w-[100%]">
                <div className="relative w-full overflow-hidden pt-[56.25%] rounded-xl">
                  <iframe
                    title={obj.title}
                    className="h-[100%] w-[100%]  absolute  top-0 bottom-0 right-0 left-0 border-none"
                    src={`https://www.youtube.com/embed/${
                      obj.url[0].split("v=")[1]
                    }`}
                  ></iframe>
                </div>
                </div>
                </div>
                </>
) : (
                <div className="text-ellipsis whitespace-nowrap overflow-hidden">
                <a
                  target="_blank"
                  rel="noreferrer"
                  className="cursor-pointer text-ellipsis"
                  href={obj.url[0]}
                >
                  {obj.title}
                </a>
                </div>
              )}
            </>
          );
        })}
    </>
  );
};

export default CarLinks;
