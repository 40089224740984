
import React, { useEffect } from "react";
import InputPrompt from "./InputPrompt";
import ChatBody from "./ChatBody";
import { useSelector } from "react-redux";

const ChatBoard= () => {

  const isLoading = useSelector((state)=>{

    return state.chatStore.isLoading;
  })
  
  useEffect(()=>{
    var objDiv = document.getElementById("scroll");
    objDiv.scrollTop = objDiv.scrollHeight;
  },[isLoading])

  return (
    <div className="md:bg-[#1f1d1d4a] pl-[40px] mt-[10px] md:mt-0 md:pl-[75px]  py-1 h-[calc(100vh-110px)] rounded-[23px] flex flex-col" >
      <div className="mb-[8px] mt-[15px] hidden md:block">
        <h2 className="text-white text-[25px] font-semibold">Ask Engage</h2>
      </div>
      <div className="flex-1 overflow-auto w-[100vw] md:max-w-[56vw] md:w-[56vw] pr-[40px] md:pr-[75px] style-3" id="scroll" >
      <ChatBody/>
      </div>
      <div className="mb-[18px] md:mb-[8px] pr-[40px] md:pr-[80px] pt-1">
        <InputPrompt />
      </div>
    </div>
  );
};

export default ChatBoard;
