import React from "react";
import InputPrompt from "./InputPrompt";
import TrendingTopics from "./TrendingTopics";

const LandingPrompt = () => {

  return (
    <div className="text-white md:w-[900px] mt-[10px] md:mt-[32px]" >
      <h1 className="px-[20px] md:px-0 md:md:font-normal font-[600] flex flex-col leading-[52px] md:leading-normal md:flex-row gap-x-4 text-[50px] md:text-[80px] mb-[25px] md:mb-[28px]"><span>Ask</span> <span>Engage</span></h1>
      <div className="px-[20px] text-[18px] md:text-[22px] md:px-0 md:mb-[19px] mb-[25px] md:leading-normal leading-[20px] ">
        <p>Have a question about <br className="md:hidden"></br>Toyota vehicles?</p>
        <p className="font-bold md:font-normal">We're here to help.</p>
      </div>
     <div className="px-[20px] md:px-0"><InputPrompt /></div>
      <TrendingTopics />
    </div>
  );
};

export default LandingPrompt;
