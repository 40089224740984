import { LoadingButton } from "@mui/lab";
import { makeStyles } from "@mui/styles";
import React, { useState } from "react";
import { IoChevronForwardOutline } from "react-icons/io5";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { signedInUser } from "../store/slices/UserSlice";

const useStyles = makeStyles({

  customButton: {
  
    "&.MuiLoadingButton-loading": {
      backgroundColor: "#ea0a1a7a !important", // Override the background color for the loading state
    },
    color: "white !important",
    '@media (max-width: 780px)': {
      height:"45px",
      justifyContent:"center"
    }
  },
});

const pause = (duration) => {
  return new Promise((resolve) => {
    setTimeout(resolve, duration);
  });
};

const AskQuestionBtn = () => {
  const [loading,setloading] = useState(false);
  const classes = useStyles();
  const navigate = useNavigate();
  const handleClick = async() => {
    setloading(true);
    await pause(2000);
    setloading(false);
    navigate("/landing");
  };
  return (
    <div className="flex flex-col items-center">
    <LoadingButton
      className={classes.customButton}
        //  loadingClassName={classes.loadingButton}
      
      sx={{
        color: "white",
        width: "300px",
        justifyContent: "space-between",
        backgroundColor: "#EA0A1A",
        borderRadius: "25px",
        "&:hover": {
          backgroundColor: "#ea0a1a7a",
        },
        boxShadow:"none"
      }}
      size="medium"
      onClick={handleClick}
      endIcon={<IoChevronForwardOutline />}
      loading={loading}
      loadingPosition="end"
      variant="contained"
    >
      <span className="flex-1 md:flex-[0] whitespace-nowrap " style={{textTransform:"math-auto"}}>Ask a Question</span>
    </LoadingButton>
    <div className="flex pl-[16px] justify-center w-[100%] p-[10px] pt-[3px] md:justify-start text-[12px]">
    No login required
  </div>
  </div>
  );
};

export default AskQuestionBtn;
