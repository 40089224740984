import React, { useEffect, useRef, useState } from "react";
import { TextField } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import { makeStyles } from "@mui/styles";

import { FaUser } from "react-icons/fa";
import { FaUnlockAlt } from "react-icons/fa";
import { LoadingButton } from "@mui/lab";
import { IoMdSend } from "react-icons/io";
import IconButton from "@mui/material/IconButton";
import { MdOutlineVisibilityOff } from "react-icons/md";
import { MdOutlineVisibility} from "react-icons/md";
import { useDispatch } from "react-redux";
import { signedInUser } from "../store/slices/UserSlice";
import { useNavigate } from "react-router-dom";
const useStyles = makeStyles({
  whiteTextField: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "white",
      },
      "&:hover fieldset": {
        borderColor: "white",
      },
      "&.Mui-focused fieldset": {
        borderColor: "white",
      },
    },
    "& .MuiInputBase-input": {
      color: "white",
    },
    "& .MuiInputLabel-root": {
      color: "white",
      fontWeight: "normal", // Initial font-weight
      "&.Mui-focused": {
        fontWeight: "lighter", // Font-weight on focus
        color: "white",
      },
    },
    "& .MuiInputAdornment-root": {
      color: "red", // Change the color of the startAdornment
     },
   

  },
  customAdornment: {
    color: "red", // Change the color of the startAdornment
  },
  customButton: {
    backgroundColor: "blue", // Change the default background color
    "&:hover": {
      backgroundColor: "darkblue", // Change the background color on hover
    },
    "&.MuiLoadingButton-loading": {
      backgroundColor: "darkblue !important", // Override the background color for the loading state
    },
    color: "white !important",
  },
});

const Wrapper = () => {
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  function handleClick() {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      dispatch(signedInUser({username:formObj.username,password:formObj.password}))
      navigate("/signin")

     
    }, 1000);
  }
  const [showPassword, setShowPassword] = React.useState(false);

  const [formObj, setformObj] = useState({
    username: "",
    password: "",
  });
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <>
      <div className="h-screen w-screen bg-oldhero bg-no-repeat bg-cover  flex justify-center items-center bg-center">
        <div className="flex flex-col gap-y-[15px]  inset-0 bg-gradient-to-t rounded-lg from-[#00000063] to-[#00000096] opacity-100 p-[31px] ">
          <TextField
        
            className={classes.whiteTextField}
            label="Username"
            variant="outlined"
            value={formObj.username}
            name="username"
            onChange={(e) =>
              setformObj({ ...formObj, [e.target.name]: e.target.value })
            }
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <FaUser className="text-white" />
                </InputAdornment>
              ),
            }}
          />

          <TextField
            className={classes.whiteTextField}
            label="Password"
            variant="outlined"
            value={formObj.password}
            name="password"
            type={showPassword ? "text" : "password"}
            onChange={(e) =>
              setformObj({ ...formObj, [e.target.name]: e.target.value })
            }
            onKeyDown={(e) => e.key === "Enter" && handleClick()}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <FaUnlockAlt className="text-white" />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment
                  position="end"
                  className={classes.customAdornment}
                >
                  <IconButton
                    className={classes.customAdornment}
                    color="primary"
                    sx={{ color: "white" }}
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? (
                      <MdOutlineVisibilityOff
                        sx={{ color: "white" }}
                        color="primary"
                        className="text-white"
                      />
                    ) : (
                      <MdOutlineVisibility
                        sx={{ color: "white" }}
                        className="text-white"
                      />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          <LoadingButton
            className={classes.customButton}
            //    loadingClassName={classes.loadingButton}
            sx={{ color: "white" }}
            size="large"
            onClick={handleClick}
            endIcon={<IoMdSend />}
            loading={loading}
            loadingPosition="end"
            variant="contained"
          >
            <span>LOGIN</span>
          </LoadingButton>
          
        </div>
      </div>
    </>
  );
};

export default Wrapper;
