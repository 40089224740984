import { InputBase } from "@mui/material";
import React from "react";
import { Paper } from "@mui/material";




const LoginInput = ({ placeholder }) => {




  return (
    <Paper
      component="form"
      sx={{
        p: "5px 7px",
        display: "flex",
        alignItems: "center",
        width: 300,
        borderRadius: "25px",
        backgroundColor: "#F4F3F1",
        boxShadow:'none'
      }}
    >
      <InputBase
    
        sx={{ ml: 1, flex: 1 , boxShadow:"none",fontSize:"11px" }}
        placeholder={placeholder}
        inputProps={{ "aria-label": placeholder }}
      />
    </Paper>
  );
};

export default LoginInput;
