import React from "react";
import { AiOutlineMenu } from "react-icons/ai";
import logo from "../assets/logo.png";
import AskQuestionBtn from "../components/AskQuestionBtn";
import LoginInput from "../components/LoginInput";
import { Checkbox, FormControlLabel } from "@mui/material";
import LoginBtn from "../components/LoginBtn";
import { MdOutlineCircle } from "react-icons/md";
import { GoCheckCircleFill } from "react-icons/go";

const SignIn = () => {
  return (
    <>
      <div className="w-screen h-[100vh] md:h-[85vh] bg-hero bg-posmobile bg-cover md:bg-posdesktop lg:bg-poslarge  md:bg-desktopsize relative">
        <nav className="flex pt-[10px] p-5 md:p-0 md:pt-[40px] ">
          <div className="md:w-[50%] text-[25px] text-white flex justify-center">
            <AiOutlineMenu className="text-white" />
          </div>
          <div className="md:w-[50%] flex-1 flex justify-center md:absolute md:right-[7%] ">
            <img src={logo} className="h-[28px]"  alt="Engage logo"/>
          </div>
        </nav>
        <div className="flex flex-col md:flex-row text-white  md:px-[20px] h-[calc(100vh-68px)] md:h-fit md:absolute md:top-[45%] md:left-[50%] md:translate-x-[-50%] md:translate-y-[-50%] md:justify-center md:pt-[35px] gap-x-[105px] md:gap-x-[15%]">
          <div className="px-[40px]  flex flex-col justify-between md:justify-center md:block flex-1 md:flex-[0]">
            <div className="custom:text-center">
            <h1 className="font-extrabold md:font-[800] text-[45px] md:text-[30px] mb-[5px] md:mb-[25px]">Ask Engage</h1>
            <div className="md:leading-[23px] text-[16px] mb-[36px]">
              <p>Have a question about </p>
              <p>Toyota vehicles?</p>
              <p className="font-bold">We're here to help</p>
            </div>
            </div>
            <AskQuestionBtn />
            
          </div>
          <div className="px-[20px] flex flex-1 md:flex-[0] justify-center md:justify-start  bg-[#cfcec9cc] md:bg-[#80808000] ">
            <div></div>
            <div>
              <div className="text-black text flex">
                <div className="bg-white  w-[1px] mr-[45px] hidden md:block"></div>
                <div>
                  <h2 className="text-[24px] text-center font-bold md:pb-[37px] pb-[10px]  mt-[20px] md:mt-0">
                    Toyota Engage Login
                  </h2>
                  <div className="flex flex-col gap-y-4">
                    <h4 className="text-[13px] text-center">Access the full Toyota Engage Experience</h4>

                    <LoginInput placeholder="Email" />
                    <LoginInput placeholder="Password" />
                    <FormControlLabel
                      className="relative bottom-[10px]   left-[4px] text-[#181818]"
                      
                      control={
                        <Checkbox
                          sx={{
                            color: "black",
                            "&.Mui-checked": {
                              color: "black",
                            },
                            '& .MuiSvgIcon-root': { fontSize: 15}
                          }}
                          icon={<MdOutlineCircle className="text-[18px] text-[#181818]" />}
                          checkedIcon={<GoCheckCircleFill   className="text-[18px] text-[#181818]" />}
                          
                        />
                      }

                      label="Remember my username"
                    />

                    <LoginBtn />
                    <div className="flex justify-between font-[600] text-[12px] relative bottom-[13px]">
                      <span>Create account</span>
                      <span>Reset password</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="w-screen h-[15vh] hidden text-[12px]  bg-slate-50 md:flex justify-center items-center">
        <span className="font-bold mr-2">Technical Support: </span> Dealer Daily
        1-877-353-2459 | Gulf States Toyota 1-800-933-3416 | Southeast Toyota
        1-800-982-6057
      </div>
    </>
  );
};

export default SignIn;
