import React, { useImperativeHandle } from 'react'
import { Popover } from "@mui/material";
import { usePopover } from '../hooks/usePopover';

const PopOverWraper =  React.forwardRef(({children},ref) => {
 
 
  const { anchorEl, handleClick, handleClose, id, open } = usePopover();
  useImperativeHandle(ref, () => ({
    childFunction1(e) {
      handleClick(e)
        
    },
  }));
  
  return (
    <div>   <Popover
    id={id}
    open={open}
    anchorEl={anchorEl}
    onClose={handleClose}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
  > {children}</Popover></div>
  )
})

export default PopOverWraper