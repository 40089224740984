import React from 'react'
import Zoom from "react-medium-image-zoom";

import { Swiper, SwiperSlide } from "swiper/react";


import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import "react-medium-image-zoom/dist/styles.css";

import { Pagination, Navigation } from "swiper/modules";
import { useMediaQuery } from '@mui/material';


const CarGallery = ({info}) => {

    const count =  info.images.reduce((total,current)=>total+current.images.length,0)
    const matches = useMediaQuery('(max-width:600px)');


    if(count===0)
    {
      return 
    }

   
  return (<>
    <div
            className={`flex ${
              count == 1 && "justify-center"
            }  style-3 py-[30px] md:py-[44px]`}
          >
            <Swiper
              slidesPerView={matches?1:(count==1 || count==2 )?1:3}
              spaceBetween={10}
              pagination={{
                clickable: true,
              }}
              modules={[Pagination, Navigation]}
              className="mySwiper"
              navigation={matches?false:(count==2 || count>3)?true:false}
            >
              {info.images.map((obj) => {
                return (
                  <>
                    {obj?.images?.map((x) => {
                      return (
                        <SwiperSlide>
                          <Zoom>
                            <div className="flex justify-center items-center flex-col cursor-pointer">
                              <img className="max-w-[900px]  min-w-[200px] " src={x.url?x.url:x} />{" "}
                              <p className='capitalize'>{x?.color}{x?.color_id && <>({x.color_id})</>} </p>
                            </div>
                          </Zoom>
                        </SwiperSlide>
                      );
                    })}
                  </>
                );
              })}
            </Swiper>
          </div>

     
 </> )
}

export default CarGallery