import React from 'react'
import { PiShareFatLight } from "react-icons/pi"
const Share = () => {
  return (
    <div className="flex items-center text-[20px] gap-x-[2px] absolute bottom-3 right-5">
    <PiShareFatLight />
    <label className="!text-[12px] relative bottom-[1px] " >Share</label>
    </div>
  )
}

export default Share