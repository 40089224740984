import React from "react";
import { useTypeWriter } from "../hooks/useTypeWriter";
import { ReactMark } from "./ReactMark";

import CarGallery from "./CarGallery";
import CarLinks from "./CarLinks";
const AnimateText = ({ info, animate }) => {
  const { displayResponse } = useTypeWriter(info, animate);

  return (
    <>
      <div
        className={`${animate && "typed"} pt-1 text-[12px] md:text-[15px] `}
        dangerouslySetInnerHTML={{ __html: displayResponse }}
        id={`${animate && "typed"}`}
      ></div>
      <div id={`${animate && "table"}`} className={`${animate && "hidden"}`}>
        <ReactMark info={info} />
      </div>
      <div
        id={`${animate && "link"}`}
        className={`mt-[20px] flex-col ${animate && "hidden"} text-blue-600`}
      >
        <CarLinks info={info} />
      </div>
      {info?.images?.length > 0 && (
        <div
          className={`${animate && "hidden"}`}
          id={`${animate && "images"}`}
        >
          <CarGallery info={info} />
        </div>
      )}
    </>
  );
};

export default AnimateText;
