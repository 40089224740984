import { useDispatch, useSelector } from "react-redux";
import { changeIsNew, changeSearchTerm } from "../store/slices/ChatStoreSlice";
import { fetchAnswer } from "../store/thunks/fetchAnswer";

export function useCustomDispatch() {
  const { currentQuestion: searchTerm, isLoading ,memory} = useSelector((state) => {
    return state.chatStore;
  });

  


  const dispatch = useDispatch();
  const handleIsNew = (input) => {
    if (input?.length == 0) return;

    if (isLoading) return;
    
    dispatch(changeSearchTerm(input));
    dispatch(changeIsNew(false));
    dispatch(fetchAnswer({ searchterm: input, memory:memory}));
  };
  const handleSearchChange = (e) => {
    dispatch(changeSearchTerm(e.target.value));
  };

  const handlekeydown = (event) => {
    if (event.key === "Enter" && searchTerm != null && searchTerm != "") {
      handleIsNew(searchTerm);
    }
  };

  return {
    handleIsNew,
    searchTerm,
    isLoading,
    handleSearchChange,
    handlekeydown,
  };
}
