import { LoadingButton } from "@mui/lab";
import React from "react";

const LoginBtn = () => {

  return (
    <LoadingButton
      // className={classes.customButton}
      //    loadingClassName={classes.loadingButton}
      sx={{ color: "white", backgroundColor: "black", borderRadius: "25px", boxShadow:"none",textTransform:"capitalize",
      "&:hover": {
        backgroundColor: "#00000085",
      }, }}
      size="medium"
      // onClick={handleClick}
    //   endIcon={<IoChevronForwardOutline />}
      // loading={loading}
      loadingPosition="end"
      variant="contained"
    >
      <span>Log In</span>
    </LoadingButton>
  );
};

export default LoginBtn;
